(function()

{
    const uniqueId = 'salesbyte-widget-' + Date.now();

    class SalesByteModalWidget {
        constructor(options = {}) {
            if (!options.iframeSrc){
                throw 'IframeSrc not provided for modal widget'
            }

            this.loadingSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="200" height="200" style="shape-rendering: auto; display: block; background: rgb(255, 255, 255); --darkreader-inline-bgimage: initial; --darkreader-inline-bgcolor: #181a1b;" data-darkreader-inline-bgimage="" data-darkreader-inline-bgcolor="" xmlns:xlink="http://www.w3.org/1999/xlink"><g><circle stroke-width="2" stroke="#e90c59" fill="none" r="0" cy="50" cx="50">\n' +
            '  <animate begin="0s" calcMode="spline" keySplines="0 0.2 0.8 1" keyTimes="0;1" values="0;40" dur="1.3157894736842106s" repeatCount="indefinite" attributeName="r"></animate>\n' +
            '  <animate begin="0s" calcMode="spline" keySplines="0.2 0 0.8 1" keyTimes="0;1" values="1;0" dur="1.3157894736842106s" repeatCount="indefinite" attributeName="opacity"></animate>\n' +
            '</circle><circle stroke-width="2" stroke="#46dff0" fill="none" r="0" cy="50" cx="50">\n' +
            '  <animate begin="-0.6578947368421053s" calcMode="spline" keySplines="0 0.2 0.8 1" keyTimes="0;1" values="0;40" dur="1.3157894736842106s" repeatCount="indefinite" attributeName="r"></animate>\n' +
            '  <animate begin="-0.6578947368421053s" calcMode="spline" keySplines="0.2 0 0.8 1" keyTimes="0;1" values="1;0" dur="1.3157894736842106s" repeatCount="indefinite" attributeName="opacity"></animate>\n' +
            '</circle><g></g></g><!-- [ldio] generated by https://loading.io --></svg>';

            this.iframeSrc = options.iframeSrc;
            this.modalId = uniqueId + '-modal';
            this.iframeId = uniqueId + '-iframe';
            this.styleId = uniqueId + '-style';
            this.formHeight = 500;
            this.formWidth = 375;

            this.modalOverlay = null;
            this.modalContent = null;
            this.iframe = null;
            this.rendered = false;

            this.phase = 1;

            this.injectStyles();
        }

        openModal() {
            window.addEventListener('message', this.handleMessage.bind(this), false);

            this.formHeight = 500;            // Create modal overlay
            this.formWidth = 375;

            this.modalOverlay = document.createElement('div');
            this.modalOverlay.id = this.modalId;

            // Modal content container
            this.modalContent = document.createElement('div');
            this.modalContent.className = 'modal-content';

            this.loader = document.createElement('div');
            this.loader.style.display = 'flex';
            this.loader.style.justifyContent = 'center';
            this.loader.innerHTML = this.loadingSvg;
            this.loader.style.margin = '0px auto';

            this.updateStyles();
            this.injectStyles();

            // Close button
            const closeButton = document.createElement('span');
            closeButton.className = 'close-button';
            closeButton.innerHTML = '&times;';
            closeButton.onclick = () => this.closeModal();

            this.modalContent.appendChild(closeButton);
            this.modalContent.appendChild(this.loader);
            this.modalOverlay.appendChild(this.modalContent);
            document.body.appendChild(this.modalOverlay);

            // Iframe
            this.iframe = document.createElement('iframe');
            this.iframe.id = this.iframeId;
            this.iframe.src = `${this.iframeSrc}&iframeId=${uniqueId}`;
            this.iframe.name = Date.now();
            this.iframe.style.width = '100%';
            this.iframe.style.height = '100%';
            this.iframe.style.borderRadius = "12px 12px 0 0";
            this.iframe.style.border = "0px solid transparent";
            this.iframe.style.scrollbarWidth = "none";
            this.iframe.style.visibility = "hidden";
            this.iframe.style.position = "absolute";

            this.modalContent.appendChild(this.iframe);
        }

        closeModal() {
            this.formHeight = 500;
            this.formWidth = 375;

            if (this.modalOverlay) {
                document.body.removeChild(this.modalOverlay);
                this.modalOverlay = null;
                this.modalContent = null;
                this.iframe = null;
                this.phase = 1;
                this.rendered = false;
                this.quoteViewed = false;
                document.head.removeChild(document.getElementById(this.styleId));
            }
        }
        handleMessage(event) {
            const payload = String(event.data).split('|');
            if (!Array.isArray(payload)){
                return;
            }

            if (payload.length < 4){
                return;
            }

            if (payload[0] !== uniqueId && payload[0] !== 'unknown'){
                return;
            }

            if (payload[1] === 'quote') {
                this.phase = 2;
                this.updateStyles();
                this.injectStyles();
            }else if(payload[1] === 'form'){
                if (payload[2] === 'dim' && payload[3].split('X').length === 2){
                    if (!this.rendered){
                        this.loader.style.display = 'none';
                        this.iframe.style.visibility = 'visible';
                        this.iframe.style.position = 'static';
                        this.rendered = true;
                    }

                    const dimensions =  payload[3].split('X');
                    this.formHeight = Math.round(Number(dimensions[0]));
                    this.formWidth = Math.round(Number(dimensions[1]));
                    this.updateStyles();
                    this.injectStyles();
                }
            }
        }

        updateStyles() {
            if (this.modalContent) {
                if (this.phase === 1) {
                    this.modalContent.classList.remove('phase-2');
                    this.modalContent.classList.add('phase-1');
                    if (this.iframe){
                        this.iframe.style.borderRadius = "12px";
                    }
                } else if (this.phase === 2) {
                    this.modalContent.classList.remove('phase-1');
                    this.modalContent.classList.add('phase-2');
                    if (this.iframe) {
                        this.iframe.style.borderRadius = "12px 12px 0 0";
                    }
                }
            }
        }

        injectStyles() {

            const styles = `
        /* Modal overlay */
        #${this.modalId} {
          position: fixed;
          z-index: 10000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.5);
          display: flex!important;
          justify-content: center;
          align-items: center;
        }
        /* Modal content base styles */
        #${this.modalId} .modal-content {
          background-color: #fff;
          padding: 0;
          border: 1px solid #888;
          box-shadow: 0 5px 15px rgba(0,0,0,0.3);
          border-radius: 12px;
          max-height: 100vh;
          max-width: 100vw;
          display: flex;
          align-items: center;
          transition: width 0.2s, height 0.3s ease-in-out;
        }
        /* Phase 1 styles */
        #${this.modalId} .modal-content.phase-1 {
          width: ${this.formWidth + 50}px;
          height: ${this.formHeight+50}px;
        }
        /* Phase 2 styles */
        #${this.modalId} .modal-content.phase-2 {
          margin-top: 10vh; /* 20% top margin */
          margin-bottom: 0; /* 0 bottom margin */
          height: calc(90vh); /* 80% of viewport height */
          width: 75vw;
          border-radius: 12px 12px 0 0 !important;
        }
        /* Mobile styles */
        @media (max-width: 768px) {
          #${this.modalId} .modal-content {
            width: 100% !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            height: 100% !important;
            max-height: 100% !important;
            border-radius: 0 !important;
          }
            /* Close button */
            #${this.modalId} .close-button {
              color: gray !important;
            }
        }
        /* Close button */
        #${this.modalId} .close-button {
          color: white;
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 50px;
          font-width: bold;
          cursor: pointer;
        }
        #${this.modalId} .close-button:hover,
        #${this.modalId} .close-button:focus {
          color: red;
          text-decoration: none;
          cursor: pointer;
          font-size: 50px
        }
      `;

            let styleSheet = document.getElementById(this.styleId);
            if (styleSheet){
                document.head.removeChild(styleSheet);
            }

            styleSheet = document.createElement('style');
            styleSheet.type = 'text/css';
            styleSheet.id = this.styleId;
            styleSheet.appendChild(document.createTextNode(styles));
            document.head.appendChild(styleSheet);
        }
    }

    window.SalesByteModalWidget = SalesByteModalWidget;
})();
